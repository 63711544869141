import { createRef, Component } from 'react';
import key from 'weak-key';
import { supportsPassive } from '~/utils/utils';

/**
 * TextScroller
 * @category Component
 * @extends Component
 * @summary Displays the text scroller component.
 * @return {Node} React node containing the text scroller component.
 */

class TextScroller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wordIndex: 0,
      words: [
        'organize',
        'honor',
        'shine',
        'flourish',
        'lol',
        'achieve',
        'heal',
        'forgive',
        'forget',
        'love',
        'dream',
        'cry',
        'cook',
        'make-up',
        'get up',
        'hug',
        'cheer',
        'jump',
        'admire',
        'blossom',
        'enjoy'
      ]
    };
    this.textScroller = createRef();
    this.scrollPos = 0;
    this.tick = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, supportsPassive ? { passive: true } : false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll = () => {
    this.scrollPos = document.body.scrollTop || window.scrollY;

    if (!this.tick) {
      window.requestAnimationFrame(() => {
        this.setWordIndex(this.scrollPos);
        this.tick = false;
      });

      this.tick = true;
    }
  }

  setWordIndex(scrollPos) {
    if(this.textScroller.current) {
      const { words } = this.state;
      const offsetTop = this.textScroller.current.getBoundingClientRect().top + document.documentElement.scrollTop
      const windowP = (scrollPos - (offsetTop - 300)) / (this.textScroller.current.offsetHeight) * 100;
      const wordP = (windowP / 100) * words.length;
      const wordIndex = Math.round(wordP);

      if(wordIndex >= 0 && wordIndex < words.length) {
        this.setState({
          wordIndex
        });
      }
    }
  }

  getLine(word, i) {
    const { wordIndex } = this.state;

    let opacity = 0;

    // Brute Force Falloff / Decay

    if(wordIndex === i) {
      opacity = 1;
    }

    // Up

    if(wordIndex === i - 1) {
      opacity = 0.8;
    }

    if(wordIndex === i - 2) {
      opacity = 0.5;
    }

    if(wordIndex === i - 3) {
      opacity = 0.3;
    }

    if(wordIndex === i - 4) {
      opacity = 0.1;
    }

    // Down

    if(wordIndex === i + 1) {
      opacity = 0.8;
    }

    if(wordIndex === i + 2) {
      opacity = 0.5;
    }

    if(wordIndex === i + 3) {
      opacity = 0.3;
    }

    if(wordIndex === i + 4) {
      opacity = 0.1;
    }

    return(
      <div key={`text-${key({word})}`}>
        <span className={`text-scroller__text${wordIndex === i ? ' text-scroller__text--active' : ''}`}>
          <span className='text-scroller__text-left'>we get to</span>
          <span className='text-scroller__text-center' style={{ opacity }}>{ word }</span>
          <span className='text-scroller__text-right'>together</span>
        </span>
      </div>
    );
  }

  render() {
    const { words } = this.state;

    return (
      <div>
        <div className="text-scroller" ref={ this.textScroller } aria-hidden='true'>
          {words.map((word, i) => this.getLine(word, i))}
        </div>
        <ul className='visuallyhidden'>
          {words.map((word) => (
            <li key={`aria-line-${key({word})}`}>we get to { word } together</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default TextScroller;