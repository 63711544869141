import { Component } from 'react';
import Head from 'next/head';
import { request } from 'graphql-request';
import dynamic from 'next/dynamic';
import { getBlocksFromQueue } from '~/utils/utils';
import About from '~/components/about/about';
import SiteSettings from '~/siteSettings';
import PageWrapper from '~/components/wrappers/pageWrapper';
import BlockParser from '~/components/blockParser/blockParser';

// GQL
import GET_ABOUT from '~/graphql/GET_ABOUT.graphql';

const Utag = dynamic(() => import('~/components/utag/utag'), { ssr: false });
const BidBarrelReact = dynamic(() => import('~/components/bidBarrelReact/bidBarrelReact'), { ssr: false });

/**
 * Home
 * @category Page
 * @extends Component
 * @summary Display the Home page.
 * @return {Node} React node containing the Home page.
 */

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { baseUrl, sitePrimaryRsid, data, about } = this.props;
    const pageTitle = `${ SiteSettings.siteName }`;
    const pageDesc = SiteSettings.defaultDesc;
    const keywords = SiteSettings.defaultKeywords;
    const blockItems = data?.entitySubQueueQuery?.items;
    const schemaObject = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      mainEntityOfPage: {
        '@type': 'WebSite',
        '@id': `${ baseUrl }/`
      },
      publisher: {
        name: SiteSettings.siteName,
        '@type': 'Organization',
        sameAs: [
          baseUrl,
          'https://www.facebook.com/TheDrewBarrymoreShow',
          'https://www.instagram.com/TheDrewBarrymoreShow/',
          'https://twitter.com/DrewBarrymoreTV',
          'https://www.youtube.com/TheDrewBarrymoreShow',
          'https://www.snapchat.com/add/drewbarrymoretv',
          'https://www.pinterest.com/thedrewbarrymoreshow/'
        ],
        'logo': {
          '@type': 'ImageObject',
          'url': `${ baseUrl }${ '/img/drew-logo.svg' }`,
          'width': '',
          'height': ''
        }
      },
      url: `${ baseUrl }/`,
      description: pageDesc,
      keywords: keywords.split(','),
    };

    return(
      <>
        <Head>
          <title>{ pageTitle }</title>
          {/* Description */ }
          <meta name='description' content={ pageDesc } />
          <meta property='og:description' content={ pageDesc } />
          <meta name='description' content={ pageDesc } />
          <meta name='twitter:description' content={ pageDesc } />
          {/* Title */ }
          <meta name='title' content={ pageTitle } />
          <meta property='og:title' content={ pageTitle } />
          <meta property='og:site_name' content={ pageTitle } />
          <meta property='og:image' content='' />
          <meta name='twitter:title' content={ pageTitle } />
          <meta name='twitter:site' content='@DrewBarrymoreTV' />
          <meta name='twitter:site:id' content='' />
          <meta name='twitter:url' content={ `${ baseUrl }/` } />
          <meta name='fb:pages' content='' />
          <meta name='fb:admins' content='' />
          <meta name='fb:app_id' content='' />
          {/* Others */ }
          <meta property='og:url' content={ `${ baseUrl }/` } />
          <link rel='canonical' href={`${ baseUrl }/`} />
          <meta name='keywords' content={ keywords } />
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaObject) }}
          />
        </Head>
        { blockItems &&
          <div className="homepage-blocks">
            <BlockParser blockItems={ blockItems } />
          </div>
        }
        <About data={ about } />
        <div data-ad-ref='leader-plus-flex-bottom' className='bidbarrel-ad'></div>
        <div data-ad-ref='mobile-plus-flex-bottom' className='bidbarrel-ad'></div>
        <Utag pageType='front_door' siteSection='home' data={{ sitePrimaryRsid }} />
        <BidBarrelReact
          mapToAdUnits={{
            mobile: [
              'mobile-omni-skybox-plus-sticky',
              'mobile-banner-top',
              'mobile-plus-flex-middle',
              'mobile-plus-flex-inc',
              'mobile-plus-flex-bottom'
            ],
            desktop: [
              'omni-skybox-plus-sticky',
              'leader-plus-flex-top',
              'leader-plus-flex-middle',
              'leader-plus-flex-inc',
              'leader-plus-flex-bottom'
            ]
          }}
        />
      </>
    );
  }
}

export async function getStaticProps() {
  const data = await getBlocksFromQueue({
    target_id: 'homepage_blocks',
    limit: 100
  });

  // Get Data
  const about = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', GET_ABOUT, {});

  return {
    props: {
      data,
      about
    },
    revalidate: 1
  }
}

export default PageWrapper(Home);
